import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { debounce } from 'lodash'
import './Blogs.scss'
import { Helmet } from 'react-helmet'
import BlogList from './BlogList'
import {
  DISPLAY_TAG_IDENTIFIER,
  POKER_BLOGS_TAG_IDENTIFIER,
  CARROM_BLOGS_TAG_IDENTIFIER,
  RUMMY_BLOGS_TAG_IDENTIFIER,
  POOL_BLOGS_TAG_IDENTIFIER,
  CHESS_BLOGS_TAG_IDENTIFIER,
  COMPETITOR_BLOGS_TAG_IDENTIFIER,
  EARN_MONEY_BLOGS_TAG_IDENTIFIER,
  GAMBLING_BLOGS_TAG_IDENTIFIER,
  LISTINGS_BLOGS_TAG_IDENTIFIER,
  REAL_MONEY_GAMES_BLOGS_TAG_IDENTIFIER,
  AUTHOR_BLOGS_TAG_IDENTIFIER,
  CRICKET_BLOGS_TAG_IDENTIFIER,
} from './constants'
import BlogSearchInput from './BlogSearchInput'
import useCommonFunctionality from './useCommonFunctionality'
import { generateBlogItemsSchema } from '../../lib/generate-schema'

interface Props {
  tags: string[]
  prefix: string
  blogType?: string
  gameCategory?: string
  excludeRummyPoker?: boolean
  displayFilters?: boolean
  searchOption?: boolean
  title: string | React.ReactNode
}

const Blogs: React.FC<Props> = ({
  tags,
  prefix,
  title,
  blogType,
  gameCategory,
  excludeRummyPoker,
  displayFilters = false,
  searchOption = true,
}) => {
  const {
    setSearchTermPoker,
    setSearchTermCarrom,
    setSearchTermRummy,
    setSearchTermPool,
    setSearchTermChess,
    setSearchTermEarnMoney,
    setSearchTermGambling,
    setSearchTermListings,
    setSearchTermRealMoneyGames,
    setSearchTermCricket,
    setSearchTermRummyRMG,
    setSearchTermPokerRMG,
    blogs,
  } = useCommonFunctionality({ tags })

  let blogIdentifier: unknown

  if (blogType === 'carrom') {
    blogIdentifier = CARROM_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'rummy') {
    blogIdentifier = RUMMY_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'pool') {
    blogIdentifier = POOL_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'chess') {
    blogIdentifier = CHESS_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'compare') {
    blogIdentifier = COMPETITOR_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'earn-money') {
    blogIdentifier = EARN_MONEY_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'gambling') {
    blogIdentifier = GAMBLING_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'listings') {
    blogIdentifier = LISTINGS_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'realMoneyGames') {
    blogIdentifier = REAL_MONEY_GAMES_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'authors') {
    blogIdentifier = AUTHOR_BLOGS_TAG_IDENTIFIER
  } else if (blogType === 'cricket') {
    blogIdentifier = CRICKET_BLOGS_TAG_IDENTIFIER
  } else {
    blogIdentifier = POKER_BLOGS_TAG_IDENTIFIER
  }
  const [selectedTag, setSelectedTag] = useState(blogIdentifier)
  const [rmgTag] = useState(gameCategory)

  let filteredBlogs = blogs
  if (selectedTag !== blogIdentifier) {
    filteredBlogs = filteredBlogs.filter((b: any) =>
      b.tags.reduce((has: boolean, t: any) => {
        if (has) return has
        return t.name === selectedTag
      }, false)
    )
  }

  if (rmgTag && filteredBlogs.length) {
    filteredBlogs = filteredBlogs.filter((b: any) =>
      b.tags.reduce((has: boolean, t: any) => {
        if (has) return has
        return t.name === DISPLAY_TAG_IDENTIFIER + rmgTag
      }, false)
    )
  }

  if (excludeRummyPoker && filteredBlogs.length) {
    filteredBlogs = filteredBlogs.filter(
      (b: any) =>
        !b.tags.some((obj: { name: string }) => {
          if (obj.name === 'display/Poker' || obj.name === 'display/Rummy') {
            return true
          }
          return false
        })
    )
  }

  const displayTags: string[] = blogs.reduce((tgs: string[], b: any) => {
    return [
      ...tgs,
      ...b.tags.reduce((dt: string[], t: any) => {
        if (
          t.name.startsWith(DISPLAY_TAG_IDENTIFIER) &&
          !t.name.includes('display/Rummy') &&
          !t.name.includes('display/Poker') &&
          !tgs.includes(t.name)
        ) {
          return [...dt, t.name]
        }
        return dt
      }, []),
    ]
  }, [])

  const updateSearchTerm = debounce((s: string) => {
    setSelectedTag(blogIdentifier)
    if (blogType === 'carrom') {
      setSearchTermCarrom(s)
    } else if (blogType === 'rummy') {
      setSearchTermRummy(s)
    } else if (blogType === 'pool') {
      setSearchTermPool(s)
    } else if (blogType === 'chess') {
      setSearchTermChess(s)
    } else if (blogType === 'earn-money') {
      setSearchTermEarnMoney(s)
    } else if (blogType === 'gambling') {
      setSearchTermGambling(s)
    } else if (blogType === 'listings') {
      setSearchTermListings(s)
    } else if (blogType === 'realMoneyGames') {
      setSearchTermRealMoneyGames(s)
    } else if (blogType === 'cricket') {
      setSearchTermCricket(s)
    } else if (blogType === 'rummyRmg') {
      setSearchTermRummyRMG(s)
    } else if (blogType === 'pokerRmg') {
      setSearchTermPokerRMG(s)
    } else {
      setSearchTermPoker(s)
    }
  }, 500)

  const blogItems = filteredBlogs.map((b: any) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = `${b.html}`

    let firstParagraphText = ''
    // Extract the first paragraph
    const firstParagraph = tempDiv.querySelector('p')
    const otherParagraph = tempDiv.querySelector('#table ~ p')

    // If you want the text content of the paragraph
    if (firstParagraph) {
      firstParagraphText = firstParagraph.textContent
      if (firstParagraphText.includes('App Name')) {
        firstParagraphText = otherParagraph?.textContent
      }
    } else {
      firstParagraphText = otherParagraph?.textContent
    }
    return (
      <BlogList
        key={b.id}
        title={b.title}
        slug={b.slug}
        tags={b.tags}
        html={blogType === 'authors' ? b.html : firstParagraphText}
        author={b.primary_author.name}
        feature_image={b.feature_image}
        prefix={b.prefix || prefix}
        reading_time={b.reading_time}
        updated_at={b.updated_at}
        layout={blogType === 'authors' ? 'blogAuthors' : 'normal'}
      />
    )
  })

  const blogItemsSchema = JSON.stringify(
    generateBlogItemsSchema({
      blogPrefix: prefix,
      blogItems: filteredBlogs,
    })
  )

  return (
    <div id="Blogs">
      <Container>
        {blogType !== 'authors' && (
          <Row>
            <Col>
              <div className="header">
                <h1 className="title">{title}</h1>
                {searchOption && (
                  <BlogSearchInput onChange={s => updateSearchTerm(s)} />
                )}
              </div>
            </Col>
          </Row>
        )}

        <Row>
          {displayFilters && (
            <div className="col-12 display-pills">
              <button
                type="button"
                className={`tag-pill ${
                  selectedTag === blogIdentifier && 'selected'
                }`}
                onClick={() => {
                  setSelectedTag(blogIdentifier)
                }}
              >
                All
              </button>
              {displayTags.map(d => {
                return (
                  <button
                    key={d}
                    type="button"
                    className={`tag-pill ${selectedTag === d && 'selected'}`}
                    onClick={() => {
                      setSelectedTag(d)
                    }}
                    title={d.replace(DISPLAY_TAG_IDENTIFIER, '')}
                  >
                    {d.replace(DISPLAY_TAG_IDENTIFIER, '')}
                  </button>
                )
              })}
            </div>
          )}
          <Col>
            {blogType !== 'authors' && (
              <div className="blogs-count">
                {`${filteredBlogs?.length} Article${
                  filteredBlogs.length > 1 ? 's' : ''
                } found in 
              this Guide`}
              </div>
            )}
            <Helmet>
              <script type="application/ld+json">{blogItemsSchema}</script>
            </Helmet>
            <div className="blogs-list">{blogItems}</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Blogs
