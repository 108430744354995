/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { useLocation } from '@reach/router'
import 'bootstrap/dist/css/bootstrap.min.css'
import './layout.scss'
import { Breadcrumb } from './Breadcrumbs/Breadcrumbs'
import { pokerOneLinksOtherPage, rummyOneLinksOtherPage } from './one-links'
import Header from './Header/Header'
//import PopularSearchLinks from './PopularSearchLinks/Popular-searchlinks'
const FooterDesk = loadable(() => pMinDelay(import('./Footer/FooterDesk'), 200))

// const QuickLinks = loadable(() =>
//   pMinDelay(import('./QuickLinks/QuickLinks'), 200)
// )
const DesktopStickyFooter = loadable(() =>
  pMinDelay(import('./Download/DesktopStickyFooter'), 200)
)
const Popup = loadable(() => import('./Popup/Popup'))
interface Props {
  children: React.ReactNode
  showBreadcrumbs?: boolean
  breadcrumbs?: Breadcrumb[]
  apkLink?: string
  desktopBreadcrumbColor?: string
  hideMobileFooter?: boolean
  hideMobileHeader?: boolean
  hideDownloadPopUp?: boolean
  marqueeText?: string
  showPokerForm?: boolean
  showRummyForm?: boolean
  pokerOneLink?: Record<'android' | 'ios' | 'web', string>
  rummyOneLink?: Record<'android' | 'ios' | 'web', string>
  blogPage?: boolean
  showSearchFunc?: unknown
  showSearchBar?: boolean
  lang?: string
  langPost?: any
  customLogo?: boolean
  pageName?: string
  backgroundColor?: string
  popularSearchLinksBg?: string
  headerStyles?: React.CSSProperties
}

const Layout: React.FC<Props> = ({
  children,
  showBreadcrumbs = false,
  breadcrumbs,
  apkLink,
  desktopBreadcrumbColor = 'white',
  hideMobileFooter = false,
  hideMobileHeader = false,
  hideDownloadPopUp = false,
  marqueeText,
  blogPage,
  showSearchFunc,
  showSearchBar,
  showPokerForm = false,
  showRummyForm = false,
  pokerOneLink,
  rummyOneLink,
  lang,
  langPost,
  customLogo,
  backgroundColor,
  //popularSearchLinksBg,
  headerStyles,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [marqueeTexts, setMarqueeTexts] = useState('')
  const [footerType, setFooterType] = useState<'poker' | 'rummy' | undefined>()

  const blogPokerOneLink = pokerOneLink || pokerOneLinksOtherPage
  const blogRummyOneLink = rummyOneLink || rummyOneLinksOtherPage

  const location = useLocation()
  const pageName = location.pathname
  useEffect(() => {
    const KEY = 'popupDisplayed'
    // const t = setTimeout(() => {
    //   try {
    //     const displayedInSession = JSON.parse(
    //       sessionStorage.getItem(KEY) || 'false'
    //     )

    //     if (!displayedInSession && !hideDownloadPopUp) {
    //       setIsPopupOpen(true)
    //       sessionStorage.setItem(KEY, 'true')
    //     }
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }, 30 * 1000)

    return () => {
      // clearTimeout(t)
    }
  }, [hideDownloadPopUp])

  const getHeaderLayout = () => {
    if (!hideMobileHeader) {
      return (
        <Header
          headerStyles={{ backgroundColor: backgroundColor, ...headerStyles }}
          showBreadcrumbs={showBreadcrumbs}
          breadcrumbs={breadcrumbs}
          desktopBreadcrumbColor={desktopBreadcrumbColor}
          showSearch={blogPage}
          toggleSearch={showSearchFunc}
          searchShow={showSearchBar}
          rummyLink={blogPage ? blogRummyOneLink : rummyOneLink}
          pokerLink={blogPage ? blogPokerOneLink : pokerOneLink}
          game={footerType}
          lang={lang}
          langPost={langPost}
          customLogo={customLogo}
          blogPage={blogPage}
        />
      )
    }
    return null
  }

  const getPopupLayout = () => {
    if (isPopupOpen) {
      return (
        <Popup
          close={() => {
            setIsPopupOpen(false)
          }}
          apkLink={apkLink}
          showPokerOnly={showPokerForm}
          showRummyOnly={showRummyForm}
          lang={lang}
          rummyLink={blogPage ? blogRummyOneLink : rummyOneLink}
          pokerLink={blogPage ? blogPokerOneLink : pokerOneLink}
        />
      )
    }
    return null
  }

  const defaultRummyMarquee =
    lang === 'hindi'
      ? '₹20,000 वेलकम बोनस प्राप्त करें | कूपन कोड का प्रयोग करें: NEW20K'
      : 'Get ₹20,000 Welcome Bonus | Use Coupon Code: NEW20K'

  if (showPokerForm && !showRummyForm) {
    setFooterType('poker')
  } else if (!showPokerForm && showRummyForm) {
    setFooterType('rummy')
    setMarqueeTexts(defaultRummyMarquee)
  }
  const innerLayout = (
    <>
      {getHeaderLayout()}
      <main
        className={lang === 'hindi' ? 'main hindi-page' : 'main'}
        style={{
          background: backgroundColor,
        }}
      >
        {children}

        {/* {pageName === '/' || pageName?.startsWith('/poker') ? (
          <PopularSearchLinks
            pageName={pageName}
            backgroundColor={backgroundColor}
             linksBg={PopularSearchLinks}
          />
        ) : (
          ''// <QuickLinks />
        )} */}
      </main>
      {hideMobileFooter ? (
        ''
      ) : (
        <>
          <FooterDesk
            rummyLink={blogPage ? blogRummyOneLink : rummyOneLink}
            pokerLink={blogPage ? blogPokerOneLink : pokerOneLink}
            lang={lang}
            showInternalLinking={blogPage ? false : true}
          />
        </>
      )}
      {/* <DesktopStickyFooter
        marqueeText={marqueeTexts || marqueeText}
        gameType={footerType}
        rummyLink={blogPage ? blogRummyOneLink : rummyOneLink}
        pokerLink={blogPage ? blogPokerOneLink : pokerOneLink}
        lang={lang}
      /> */}
      {getPopupLayout()}
    </>
  )

  return <>{innerLayout}</>
}

export default Layout
