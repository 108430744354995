import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import './UpdatedHomeFAQs.scss'
import { UpdatedFaq } from '../../FAQs/faqs-list-interface'
import FAQ from '../../FAQs/FAQ'
import useFaqExpansion from '../../../hooks/useFaqExpansion'
import { FAQS } from '../../internal-links'
import { Container } from 'react-bootstrap'
import { CloseAppFAQs, FaqSingle, FAQType, NewFAQs } from '../../FAQs/faqs-list'

interface Props {
  faqs?: UpdatedFaq[][]
  allFaqText?: string
  hideAllFaqsLink?: boolean
  faqTitle?: string
  color?: string
}

const UpdatedHomeFAQs: React.FC<Props> = ({
  faqs,
  allFaqText,
  faqTitle,
  hideAllFaqsLink,
  color,
}) => {
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()
  const [defaultFaqs, _] = useState<NewFAQs[]>(CloseAppFAQs)
  const [faqsList, setFaqsList] = useState<FaqSingle[]>([])

  const [activeTab, setActiveTab] = useState(0)


  useEffect(() => {
    handleFilterFaqs(FAQType.GENERAL_QUESTIONS)
  }, [])

  const handleFilterFaqs = (type: string) => {
    const filteredFaqs = defaultFaqs.filter(faq => faq.type === type)
    setFaqsList(filteredFaqs[0].faqs)
  }

  return (
    <div id="FAQ">
      <Container>
        <h2>{faqTitle || 'FAQs'}</h2>

        <div className="content-container">
          {/* <Container> */}
          {/* <div className="tab-navigation">
            {faqTypes.map((tab, index) => (
              <button
                key={index}
                className={`tab-button ${activeTab === index ? 'active' : ''}`}
                onClick={() => {
                  handleFilterFaqs(tab)
                  setActiveTab(index)
                }}
              >
                {tab}
              </button>
            ))}
          </div> */}
          {/* </Container> */}
          <div className="content">
            {/* <div className="tab-navigation">
              {faqTypes.map((tab, index) => (
                <button
                  key={index}
                  className={`tab-button ${
                    activeTab === index ? 'active' : ''
                  }`}
                  onClick={() => {
                    handleFilterFaqs(tab)
                    setActiveTab(index)
                  }}
                >
                  {tab}
                </button>
              ))}
            </div> */}
            <div className="faqs">
              {faqsList && faqsList.length > 0 ? (
                faqsList.map((f, i) => (
                  <>
                    <FAQ
                      question={f.title}
                      answerElement={f.answer}
                      expanded={expandedFaq === i}
                      setExpanded={setExpandedFaq}
                      questinNumber={i}
                      color={color}
                    />
                    {i !== faqsList.length - 1 && <div className="separator" />}
                  </>
                ))
              ) : (
                <p>No FAQs available</p>
              )}
            </div>

            {!hideAllFaqsLink && (
              <Link to={FAQS} className="cta">
                <div>{allFaqText || 'GO TO FAQ PAGE'}</div>
                <div className="right-icon">
                  <StaticImage
                    src="../../../images/index/common/arrow-right.png"
                    alt="more faqs"
                    className="arrow"
                    width={24}
                  />
                </div>
              </Link>
            )}

            {/* <div className="Uchip-left">
              <StaticImage
                src="../../../images/index/common/chip-left.png"
                alt="chip left"
                className="Uchip-img"
                quality={100}
              />
            </div>
            <div className="Uchip-right">
              <StaticImage
                src="../../../images/index/common/chip-right.png"
                alt="chip right"
                className="Uchip-img"
                quality={100}
              />
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default UpdatedHomeFAQs
