import React from 'react'
import Container from 'react-bootstrap/esm/Container'

import './TDSPolicy.scss'
import { Col, Row } from 'react-bootstrap'
import useFaqExpansion from '../../hooks/useFaqExpansion'
import { pokerTDSFaqs } from '../FAQs/faqs-list'
import FAQ from '../FAQs/FAQ'

const TDSPolicyPoker: React.FC = () => {
  const { setExpandedFaq } = useFaqExpansion()

  return (
    <div className="tds-policy-wrap">
      <Container>
        <h1>Mega Poker TDS Policy</h1>
      </Container>
      <section>
        <Container>
          <h2>TDS Policy</h2>
          <p>
            A new section 194BA has been introduced for the TDS calculation by
            the Indian tax authorities, and Mega Poker is implementing the
            policy changes accordingly.
          </p>
          <p>
            As per the new policy, a 30% TDS will be applicable on the
            player&apos;s net winnings, calculated at the time of every
            withdrawal request.{' '}
          </p>
          <h2>How will net winnings be calculated?</h2>
          <p>
            Net Winnings = Total Withdrawals, including current withdrawals
            during the financial year (<strong>FY24-25 Withdrawals</strong>)
            Total deposits by the player during the financial year (
            <strong>FY24-25 Deposits</strong>)
          </p>
          <p>
            Any amount that has been previously subjected to Tax Deducted at
            Source (TDS) during an earlier withdrawal will also be deducted when
            calculating the net winnings at the time of withdrawal, specifically
            for FY 24-25 carryforward taxed withdrawals. (FY24-25 Carryforward
            Taxed Withdrawals)
          </p>
          <p>
            If the net winnings amount is positive after calculation, the
            difference will be considered the taxable withdrawal amount on which
            the player will need to pay the applicable TDS. After deducting the
            TDS amount, the remaining balance will be processed as the final
            withdrawal amount to the player&apos;s bank account.
          </p>
          <h4>Please add TDS calculation as on December 31, 2024</h4>
        </Container>
      </section>
      <section>
        <Container>
          <h2>FAQs</h2>
          {pokerTDSFaqs.map((f, i) => {
            return (
              <React.Fragment key={f.question}>
                <FAQ
                  question={f.question}
                  answer={f.answer}
                  answerElement={f.answerElement}
                  expanded
                  setExpanded={setExpandedFaq}
                  questinNumber={i}
                  hideButtons
                />
                {i !== pokerTDSFaqs.length - 1 ? (
                  <Container>
                    <Row className="s-row">
                      <Col lg={9}>
                        <div className="separator" />
                      </Col>
                    </Row>
                  </Container>
                ) : null}
              </React.Fragment>
            )
          })}
        </Container>
      </section>
    </div>
  )
}

export default TDSPolicyPoker
