import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as links from '../internal-links'
import SocialMedia from './SocialLinks/SocialMedia'
import AppInstallFooter from '../Download/AppInstallFooter'

import './DesktopFooter.scss'

import InternalLinking from '../InternalLinking/InternalLinking'
import FooterContent from './FooterContent/FooterContent'
import { CONTACT_US, POKER_TDS, PRIVACY_POLICY, RUMMY_TDS, TERMS } from '../internal-links'

interface Props {
  pokerLink?: Record<'android' | 'ios' | 'web', string>
  rummyLink?: Record<'android' | 'ios' | 'web', string>
  lang?: string
  showInternalLinking?: boolean
}

const FooterDesk: React.FC<Props> = ({
  pokerLink,
  rummyLink,
  lang,
  showInternalLinking,
}) => {
  const data = useStaticQuery(graphql`
    {
      payTM: file(relativePath: { eq: "payment-paytm.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      gPay: file(relativePath: { eq: "payment-gpay.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      master: file(relativePath: { eq: "payment-master.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      visa: file(relativePath: { eq: "payment-visa.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      agif: file(relativePath: { eq: "agif-small-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fairness: file(relativePath: { eq: "fairness-small-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      plus: file(relativePath: { eq: "18plus-small-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      itech: file(relativePath: { eq: "itech-small-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const paymentMethods = [
    {
      image: data.payTM.childImageSharp.gatsbyImageData,
      alt: 'paytm',
    },
    {
      image: data.gPay.childImageSharp.gatsbyImageData,
      alt: 'gpay',
    },
    {
      image: data.master.childImageSharp.gatsbyImageData,
      alt: 'master',
    },
    {
      image: data.visa.childImageSharp.gatsbyImageData,
      alt: 'visa',
    },
  ]

  const cetifications = [
    {
      image: data.agif.childImageSharp.gatsbyImageData,
      alt: 'certificate1',
    },
    {
      image: data.fairness.childImageSharp.gatsbyImageData,
      alt: 'certificate2',
    },
    {
      image: data.plus.childImageSharp.gatsbyImageData,
      alt: 'plus',
    },
    {
      image: data.itech.childImageSharp.gatsbyImageData,
      alt: 'itech',
    },
  ]

  return (
    <div className="footer_block">

      <div className="footer_area">
        <div className="footer_content">
          {/* Logos Section */}
          <div className="footer_logo">
            <Link to="/" className="logo_area">
              <StaticImage
                loading="eager"
                src="../../images/getmega-logo-nbg.png"
                alt="GetMega Logo"
                className="getmega-logo"
                layout="constrained"
              />
              <StaticImage
                loading="eager"
                src="../../images/svg/logo.png"
                alt="MegaPoker Logo"
                className="megapoker-logo"
                layout="constrained"
              />
              <StaticImage
                loading="eager"
                src="../../images/megarummy-main.png"
                alt="Mega Rummy Logo"
                className="getmega-rummy-logo"
                layout="constrained"
              />
            </Link>
          </div>

          {/* Links Section */}
          <div className="footer_links">
            <h5>Supporting Links:</h5>
            <ul>
              <li>
                <p>
                  <Link to={TERMS}>Terms & Conditions</Link>
                </p>
              </li>
              <li>
                <p>
                  <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
                </p>
              </li>
              <li>
                <p>
                  <Link to={RUMMY_TDS}>Mega Rummy TDS Policy</Link>
                </p>
              </li>
              <li>
                <p>
                  <Link to={POKER_TDS}>Mega Poker TDS Policy</Link>
                </p>
              </li>
              <li>
                <p>
                  <Link to={CONTACT_US}>Contact Support</Link>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>



      {/* <div className="footer_mobile_download">
        <Container>
          <AppInstallFooter
            rummyDownloadBtnText={
              lang === 'hindi' ? 'रमी डाउनलोड करें' : 'Download Rummy'
            }
            pokerDownloadBtnText={
              lang === 'hindi' ? 'पोकर डाउनलोड करें' : 'Download Poker'
            }
            pokerLink={pokerLink}
            rummyLink={rummyLink}
            lang={lang}
          />
        </Container>
      </div> */}

      <div className="footer_payment_area">
        {/* {showInternalLinking && (
          <div className="internal_linking_area">
            <div className="title">
              <p>Quick Links</p>
            </div>
            <InternalLinking />
          </div>
        )} */}
        {/* <hr
          style={{ border: 'none', height: '0.3px', backgroundColor: 'white' }}
        />
        <FooterContent />
        <hr
          style={{ border: 'none', height: '0.3px', backgroundColor: 'white' }}
        /> */}
        {/* <Row>
            <Col>
              <div className="footer_payment_title">Secure Payment Methods</div>
              <div className="footer_payment_icons">
                {paymentMethods.map(el => {
                  return (
                    <div className="icon_container">
                      <GatsbyImage
                        image={el.image}
                        loading="eager"
                        alt={el.alt}
                        imgStyle={{ objectFit: 'contain' }}
                        className="icon_inside"
                      />
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col className="d-none d-sm-block">
              <div className="footer_media">
                <div className="footer_media_title">Follow Us On</div>
                <SocialMedia />
              </div>
            </Col>
            <Col>
              <div className="footer_payment_title">
                Security & Certifications
              </div>
              <div className="footer_payment_icons">
                {cetifications.map(el => {
                  return (
                    <div className="icon_container">
                      <GatsbyImage
                        image={el.image}
                        loading="eager"
                        alt={el.alt}
                        imgStyle={{ objectFit: 'contain' }}
                        className="icon_inside"
                      />
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
          <div className="d-sm-none">
            <div className="footer_media">
              <div className="footer_media_title">Follow Us On</div>
              <SocialMedia />
            </div>
          </div> */}

        <div className="footer_right_area">
          <p>
            © 2024{' '}
            {lang === 'hindi'
              ? 'मेगाशॉट्स इंटरनेट प्राइवेट लिमिटेड | सर्वाधिकार सुरक्षित'
              : 'Megashots Internet Private Limited | All Rights Reserved'}
          </p>
          {/* <p>
              {lang === 'hindi'
                ? 'इस खेल में वित्तीय जोखिम का एक तत्व शामिल है और इसकी लत लग सकती है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।'
                : 'This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk.'}
            </p>
            <p>
              {lang === 'hindi'
                ? 'मेघालय, आंध्र प्रदेश, तेलंगाना, असम, ओडिशा, नागालैंड और सिक्किम के खिलाड़ियों को ऑनलाइन कैश रमी खेलने की अनुमति नहीं है।'
                : 'Players from Meghalaya, Andhra Pradesh, Telangana, Assam, Odisha, Nagaland, and Sikkim are not allowed to play online cash rummy.'}{' '}
              <Link to="/">
                {lang === 'hindi' ? 'अधिक जानकारी' : 'Know More'}
              </Link>
            </p> */}
        </div>
      </div>
    </div>
  )
}

export default FooterDesk
