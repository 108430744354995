import React from 'react'
import './index.scss'
const MainBannerAnnouncement = () => {
  return (
    <div className="announcement-banner">
      <h1 className="main-heading">
        <strong>GetMega</strong> is Closing Operations, <br /> including{' '}
        <strong>Mega Rummy</strong> and <strong>Mega Poker</strong>.
        <br/>
        <p>
          Thank you for being a part of our journey.
          <br />
          For any assistance, please contact our support team.
        </p>
      </h1>
    </div>
  )
}

export default MainBannerAnnouncement
