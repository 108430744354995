import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'

import {
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateRummyReviewSchemaAndroid,
  generateRummyReviewSchemaIos,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import defaultFaqs from '../components/FAQs/faqs-list'
import { HOME_PAGE } from '../components/download-links'

import './index.scss'
import {
  pokerOneLinksHomePage,
  rummyOneLinksHomePage,
} from '../components/one-links'
import {
  HINDI_HOME,
  POKER_TDS,
  PRIVACY_POLICY,
  RUMMY_TDS,
  TERMS,
} from '../components/internal-links'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import MainBanner from '../components/Design2.0/HomePage/MainBanner/MainBanner'
import TrustFlowWidget from '../components/Trust-Flow-Widget/Trust-flow-widget'
import MainBannerAnnouncement from '../components/Design2.0/HomePage/Announcement/MainBanner'
import { Container } from 'react-bootstrap'
import InfoCard from '../components/Games/CategoryAndGamesInfo/InfoCard'
import RummyInfo from '../components/Games/CategoryAndGamesInfo/RummyInfo'
import RenderHTML from '../components/Poker/PokerVarientPages/RenderHTML'
import ReadMoreLessSections from '../components/Games/CategoryAndGamesInfo/ReadMoreLessSections'
import { Link } from '@reach/router'
import CloseApp from '../components/Design2.0/HomePage/CloseApp/CloseApp'

const UpdatedHomeFAQs = loadable(
  () => import('../components/Index/HomeFAQs/UpdatedHomeFAQs')
)

const PokerFeatures = loadable(
  () => import('../components/Design2.0/HomePage/Features/index')
)
const WhyChooseGetMega = loadable(() =>
  pMinDelay(
    import('../components/Design2.0/common/WhyChooseGetMega/WhyChooseGetMega'),
    200
  )
)
const StaySafeAndCertified = loadable(
  () =>
    import(
      '../components/Design2.0/common/StaySafeAndCertified/StaySafeAndCertified'
    )
)
const AboutGetMega = loadable(
  () => import('../components/Design2.0/HomePage/AboutGetMega/AboutGetMega')
)
const OurBlogs = loadable(
  () => import('../components/Design2.0/common/OurBlogs/OurBlogs')
)
const TestimonialsContent = loadable(
  () => import('../components/Design2.0/common/Testimonials/Testimonials')
)
const AppStore = loadable(
  () => import('../components/Design2.0/HomePage/AppStore/AppStore')
)
const HomeFAQs = loadable(() => import('../components/Index/HomeFAQs/HomeFAQs'))

declare global {
  interface Window {
    trackGLDownloadEvent: () => void
  }
}

const IndexPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allGhostPost(filter: { slug: { eq: "home-page" } }) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  const SITE_URL = data.site.siteMetadata.siteUrl

  const [isHomepage, setIsHomepage] = useState(false)
  useEffect(() => {
    // Check if the current pathname is the homepage
    if (window.location.pathname === '/') {
      setIsHomepage(true)
    } else {
      setIsHomepage(false)
    }
  }, [])

  return (
    <div id="home-page">
      <Layout
        apkLink={HOME_PAGE}
        showPokerForm
        showRummyForm
        pokerOneLink={pokerOneLinksHomePage}
        rummyOneLink={rummyOneLinksHomePage}
        pageName="/"
        langPost={[
          {
            languageName: 'english',
            pageSlug: `/`,
          },
          {
            languageName: 'hindi',
            pageSlug: `${HINDI_HOME}`,
          },
        ]}
        headerStyles={{
          backgroundColor: 'transparent',
        }}
      >
        <Helmet>
          <link rel="alternate" href={`${SITE_URL}`} hrefLang="en-in" />
          <link rel="alternate" href={`${SITE_URL}`} hrefLang="x-default" />
          <link
            rel="alternate"
            href={`${SITE_URL}${HINDI_HOME}`}
            hrefLang="hi-in"
          />
          <style type="text/css">{`
            .gatsby-image-wrapper div[data-placeholder-image] {
              opacity: 0 !important;
              pointer-events: auto;
            }
        `}</style>
        </Helmet>
        <SEO
          title={metaTitle}
          description={metaDescription}
          organizationSchema={generateOrgSchema()}
          websiteSchema={generateWebsiteSchema()}
          reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
          reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
          reviewSchemaRummyAndroid={generateRummyReviewSchemaAndroid()}
          reviewSchemaRummyIos={generateRummyReviewSchemaIos()}
          faqSchema={generateFaqSchema(defaultFaqs)}
          hideHrefLang
        />

        {/* <MainBanner /> */}

        {/* <TrustFlowWidget />

        <PokerFeatures />

        <WhyChooseGetMega />
        <StaySafeAndCertified />
        <UpdatedHomeFAQs />
        <TestimonialsContent />

        <OurBlogs page="all" />

        <AboutGetMega />
        <AppStore /> */}

        <CloseApp />
      </Layout>
    </div>
  )
}

export default IndexPage
