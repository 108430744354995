import { Link } from 'gatsby'
import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import { bannedStatesString } from '../banned-states'
import { CARDS } from '../internal-links'

import './TermsAndConditions.scss'

const TermsAndConditions: React.FC = () => {
  return (
    <div id="terms-and-conditions">
      <Container>
        <h1>
          <span className="underline">Terms</span> Of Use
        </h1>
      </Container>
      <ol className="decimal-counter o1">
        <div style={{ padding: '40px 0' }}>
          <Container>
            <li>
              <h2>Overview</h2>
              <ol className="decimal-counter o2">
                <li>
                  These Terms and Conditions govern the closure of services by
                  Megashots Internet Private Limited, operating as GetMega. By
                  continuing to interact with the platform, you agree to comply
                  with these Terms.
                </li>

              </ol>
            </li>
          </Container>
        </div>

        <div>
          <Container>
            <li>
              <h2>Service Discontinuation</h2>
              <ol className="decimal-counter o2">
                <li>All gameplay will terminate on <strong>31st December 2024.</strong></li>
                <li>Withdrawals and access to user accounts will remain available until <strong>15th January 2025.</strong>
                  After this date, accounts will be permanently deactivated, and any unclaimed balances will be forfeited.</li>
              </ol>
            </li>
          </Container>
        </div>

        <div style={{ padding: '40px 0 80' }}>
          <Container>
            <li>
              <h2>Documentation
              </h2>
              <ol className="decimal-counter o2">
                <li>
                  Users must download all invoices, statements, and transaction histories by  <strong>15th January 2025,</strong>,
                  as these will not be accessible after this date.
                </li>
                <li>
                  For TDS certificates and related documents, requests can be made until <strong>30th June 2025.</strong>
                </li>
              </ol>
            </li>

            <li>
              <h2>KYC Compliance
              </h2>
              <ol className="decimal-counter o2">
                <li>
                  KYC must be completed to claim any winnings or withdraw funds.
                  Failure to comply with KYC requirements by the closure deadline
                  will result in forfeiture of funds.
                </li>
              </ol>
            </li>

            <li>
              <h2>Contact Information</h2>
              <ol className="decimal-counter o2">
                <li>
                  For assistance, please contact:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Mega Poker: help.poker@getmega.com
                    </li>
                    <li>
                      Mega Rummy: help.rummy@getmega.com
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Amendments</h2>
              <ol className="decimal-counter o2">
                <li>
                  We reserve the right to amend these Terms at our discretion without
                  prior notice. Any changes will be effective immediately upon posting.
                </li>
              </ol>
            </li>
            <li>
              <h2>Governing Law and Jurisdiction</h2>
              <ol className="decimal-counter o2">
                <li>
                  These Terms are governed by the laws of India. Any disputes will
                  be subject to the exclusive jurisdiction of the courts in Bengaluru,
                  India.
                </li>
                <li>
                  Please ensure to complete all transactions and download required
                  documentation before the respective deadlines. After these dates,
                  access to user accounts will cease permanently.
                </li>
              </ol>
            </li>

            {/* <li>
              <h2>Passes</h2>
              <ol className="decimal-counter o2">
                <li>
                  We love having you on-board the App and to get you started, we
                  may provide you with passes (“<strong>Passes</strong>”) for
                  one or more Games, or for one or more category of Games. Each
                  Pass has a specified notional value and will enable you to
                  join a Contest that has an Entry Fee of an equal or lower
                  value. You will not be allowed to use your Pass for a Cash
                  Based Format or a MTT. The details of any Passes made
                  available to you will be reflected in your Remittance
                  Portfolio.
                </li>
                <li>
                  You may also earn Passes if you refer the App to third parties
                  who register and create a Player Profile on the App, and remit
                  qualifying minimum amounts towards playing games on the App,
                  as may be specified by us from time to time. To avail Passes
                  pursuant to your referrals, the referred Player must provide
                  us with your phone number.
                </li>
                <li>
                  All Passes have a fixed validity period and will expire if you
                  do not utilize them before their expiry.
                </li>
                <li>
                  When you enter a Contest using a Pass and are awarded
                  Winnings, basis the relevant winning criteria, the value of
                  such Pass will be deducted from such Winnings. If you lose the
                  Contest, then the value of the Pass is to our account. By way
                  of illustration:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      <em>Each pass has:</em>
                      <ol style={{ listStyleType: 'lower-roman' }}>
                        <li>
                          <strong>
                            <em>Maximum value</em>
                          </strong>
                          <em>
                            . Maximum value. This is the maximum value of a
                            single pass to join a game. To elaborate, if your
                            pass has a maximum value of 10 (ten), you can join
                            contests with entry fees of upto INR. 10 (Indian
                            Rupees Ten). For example, an INR. 5 (Indian Rupees
                            Five) or an INR 10 (Ten) contest, but you cannot use
                            the pass to join an INR 15 (Indian Rupees Fifteen)
                            or INR 20 (Indian Rupees Twenty) contest.
                          </em>
                        </li>
                        <li>
                          <strong>
                            <em>Pass fee.</em>
                          </strong>
                          <em>
                            Pass fee is the charge that is deducted from your
                            Winnings if you use a pass to join a room. A pass
                            will give you free access to a room, however, Mega
                            puts up the amount on your behalf. If you lose a
                            game then there is no charge applicable, however,
                            Mega will deduct the pass fee (room joining charge)
                            from your Winnings. Pass fee may vary for each pass
                            and the fee will be mentioned on the pass at the
                            time of exercise.
                          </em>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        <em>
                          To help you understand better, if you use a pass of
                          maximum value of 10 (ten) and pass fee of 25% (twenty
                          five percent) and you join a 10 (ten) Gem contest of
                          maximum 2 (two) players.
                        </em>
                      </p>
                      <p>
                        <em>Total game pool - 10 + 10 = 20</em>
                      </p>
                      <p>
                        <em>Winning amount - Rs 14 (Rupees Fourteen)</em>
                      </p>
                      <p>
                        <em>
                          Pass fee applicable = 25% X 10(Room joining cost) =
                          2.5
                        </em>
                      </p>
                      <p>
                        <em>
                          Final game Winnings = 14(game Winnings) - 2.5(Pass
                          fee) = 11.5
                        </em>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Refund of Entry Fee or BUY-IN</h2>
              <ol className="decimal-counter o2">
                <li>
                  In the event, you are unable to participate in a Contest after
                  paying the Entry Fee or the Buy-In, due to (a) the Contest
                  being cancelled by us; (b) requisite number of Players being
                  unavailable; or (c) our failure to match you with Players,
                  then the amount paid by you as Entry Fee or Buy-In will be
                  refunded to your Remittance Portfolio.
                </li>
                <li>
                  In the event, you are unable to participate in a Contest after
                  paying the Entry Fee or Buy-In due to (a) your failure to play
                  a Game during the period the Contest is running; (b) losing
                  internet or data connectivity; (c) the App crashing on your
                  Compatible Device (as defined below); or (d) any other reason
                  which has not been specified in paragraph 10.2, you will
                  forfeit the amount paid as Entry Fee and shall not be entitled
                  to any refund or compensation.
                </li>
              </ol>
            </li>

            <li>
              <h2>Winnings</h2>
              <ol className="decimal-counter o2">
                <li>
                  Each Contest has different criteria for winning and has
                  corresponding Winnings. Details of the winning criteria and
                  the Winnings will be provided to you at the time of joining
                  the Contest.
                </li>
                <li>
                  Winnings may include but may not be limited to monetary prizes
                  (which may either be fixed or variable based on the number of
                  Players participating in the particular Contest), or other
                  non-cash based awards such as products, discount vouchers or
                  other intangible awards.
                </li>
                <li>
                  The winning criteria as well as the Winnings will be decided
                  by us on our sole discretion.
                </li>
                <li>
                  In the event you win a Contest basis the relevant winning
                  criteria, you may win monetary or non-monetary Winnings. Only
                  monetary Winnings will reflect in your Winnings Portfolio.
                </li>
                <li>
                  In the event, you win monetary Winnings, you are entitled to
                  (a) utilise the amount to play Games on the App; or (b)
                  withdraw the amount in your bank account or wallet associated
                  with the App (“<strong>Designated Account</strong>”), subject
                  to you fulfilling all conditions specified in these Terms, to
                  our satisfaction.
                </li>
                <li>
                  Non-monetary Winnings may be claimed by following the
                  instructions provided in the App.
                </li>
                <li>
                  All Winnings will be credited to the Player subject to tax
                  deduction at source in compliance with applicable law.
                </li>
                <p>
                  <strong>
                    <em>Instant Format: No-show in a Contest</em>
                  </strong>
                </p>
                <li>
                  In the event you have joined a Contest before it commenced and
                  failed to play the Game during the period in which the Contest
                  is running and active, your Entry Fee, will be forfeited and
                  you will not be entitled to any refund or compensation for the
                  same.
                </li>
                <li>
                  Further, in the event, you participate in more than one
                  Contest, all of which are running simultaneously, and are
                  unable to play the relevant Game for any of the Contests when
                  they are running, then you will forfeit the Entry Fee you have
                  paid for such Contests.
                </li>
                <li>
                  Furthermore, you will not be entitled to any Winnings in the
                  event you fail to play the relevant Game after joining a
                  Contest.
                </li>
              </ol>
            </li>

            <li>
              <h2>Payments and Transfer of Monetary Winnings</h2>
              <ol className="decimal-counter o2">
                <p>
                  <strong>
                    <em>Payments</em>
                  </strong>
                </p>
                <li>
                  You may remit to your Remittance Portfolio using any of the
                  options available on the App including (a) through wallets you
                  link with the App; (b) debit/credit cards; (c) Unified Payment
                  Interface; (d) net banking; and (e) such other payment options
                  as are made available on the App from time to time.
                </li>
                <li>
                  You understand that these payment gateways are managed solely
                  by third party service providers and therefore your use of
                  such payment gateways will be governed by the terms and
                  conditions of such third-party service providers. You agree
                  that your use of a third-party payment gateway is at your sole
                  option and risk.
                </li>
                <li>
                  The value reflecting in your Remittance Portfolio, Winnings
                  Portfolio or the value of your Winnings from a Contest may be
                  varied by us for any reason including due to (a) change in
                  applicable tax rates; (b) change in applicable laws; or (c)
                  any other relevant changes that are applied across the App.
                  The change in the value reflected in your Remittance Portfolio
                  and/or Winnings Portfolio will only be effective after it has
                  been notified on the App.
                </li>
                <p>
                  <strong>
                    <em>Transfer of Monetary Winnings</em>
                  </strong>
                </p>
                <li>
                  You may withdraw your monetary Winnings from your Winnings
                  Portfolio to your Designated Account, subject to the following
                  conditions:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      The monetary Winnings in your Winnings Portfolio is more
                      than the minimum required balance of Rs. 5 (Rupees One
                      Hundred and Fifty only). This minimum required balance
                      amount may be revised from time to time;
                    </li>
                    <li>
                      You have completed the KYC requirements in accordance with
                      these Terms and to our complete satisfaction;
                    </li>
                    <li>
                      You may withdraw according to the maximum limit per day
                      which will be reflected and revised on the App from time
                      to time; and
                    </li>
                    <li>
                      You will be responsible for payment of processing charges,
                      taxes and complying with any applicable laws in relation
                      to any monetary Winnings earned by you;
                    </li>
                  </ol>
                </li>
                <p>
                  <strong>Withdrawal from your Remittance Portfolio</strong>
                </p>
                <li>
                  You hereby provide your consent to us to hold the amounts in
                  the Remittance Portfolio on your behalf and we will not have
                  any right rights over the amounts in the Remittance Portfolio,
                  other than as set out in these Terms. You may also withdraw
                  the amount from your Remittance Portfolio to your Designated
                  Account, subject to the following conditions:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      You have completed the KYC requirements in accordance with
                      these Terms and to our complete satisfaction;
                    </li>
                    <li>
                      You may withdraw according to the maximum limit per day
                      which will be reflected and revised on the App from time
                      to time ; and
                    </li>
                    <li>
                      You will be responsible for payment of processing charges,
                      taxes and complying with any applicable laws in relation
                      to the withdrawal;
                    </li>
                  </ol>
                </li>
                <p>
                  <strong>
                    <em>KYC</em>
                  </strong>
                </p>
                <li>
                  Since the App involves real-money gaming, we, in line with
                  industry practice and to comply with applicable laws and our
                  internal policies, mandatorily require you to complete KYC and
                  provide proof of identity.
                </li>
                <li>
                  You will be required to enter your KYC details and upload
                  necessary documents in order to complete your KYC. You will be
                  able to withdraw your Winnings only after you have completed
                  your KYC.
                </li>
                <li>
                  Pursuant to the completion of your KYC, you will only be able
                  to transfer your monetary Winnings to your Designated Account
                  for which you have completed KYC with us.
                </li>
                <li>
                  After your Winnings exceed Rs.500 (Rupees Five Hundred only),
                  you will not be able to access any Games, claim your Winnings
                  or carry out any other activity on the App until your KYC
                  documents have been uploaded to our satisfaction. Failure to
                  upload the same within the time period provided above will
                  lead to automatic closure of your Player Profile.
                </li>
                <li>
                  Upon closure of your Player Profile because of your failure to
                  fulfil the KYC requirements in accordance with these Terms, we
                  will not be liable to pay the Winnings to you and you will not
                  be entitled to make any claims against us in this regard.
                </li>
              </ol>
            </li>

            <li>
              <h2>Community</h2>
              <p>
                The App may enable you to join gaming communities and groups
                (collectively, “<strong>Community Pages</strong>”) on third
                party social media platforms. Access and use of the Community
                Pages are subject to the Terms and other policies of such
                third-party platforms.
              </p>
            </li>

            <li>
              <h2>Your Information</h2>
              <ol className="decimal-counter o2">
                <li>
                  You will ensure that the information provided by you is
                  accurate, true and up to date at all times.
                </li>
                <li>
                  You undertake that should your information change at any time
                  during your use of the App, you shall update your information
                  to ensure it remains true, current, complete and accurate at
                  all times.
                </li>
                <li>
                  In the event of any discrepancy with respect to any of the
                  information submitted by you:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      You will not be permitted to participate in any of the
                      Games; and
                    </li>
                    <li>
                      In case you are entitled to any Winnings, you will not be
                      eligible for being awarded such Winnings.
                    </li>
                  </ol>
                </li>
                <li>
                  Further, if at any time, including after disbursement of your
                  Winnings, any information submitted by you is found to be
                  incorrect, false, or otherwise misrepresented or misleading,
                  you shall be liable to refund all amounts received by you form
                  us.
                </li>
                <li>
                  By providing us with the information, you represent and
                  warrant that the contact number supplied by you is not part of
                  any “do not call” registry or its equivalent, anywhere in the
                  world and that we may use the information to send you
                  communication, notices or alerts that are transactional,
                  informational and promotional in nature, from time to time.
                </li>
                <li>
                  You expressly grant us the right to monitor your online
                  activities in relation to the App including but not limited to
                  logging of your IP address to verify your geographical
                  location.
                </li>
                <li>
                  Furthermore, you provide your consent to us to display all or
                  part of your Player Profile which may include your name,
                  photograph (if any), and your total score, on the App’s
                  leadership board. You also provide your consent to us to use
                  either the details provided by you for your Player Profile or
                  all or part of your Player Profile for the purposes of
                  marketing, promotions and/or other such related purposes on
                  print, digital, social media and/or other marketing channels.
                </li>
              </ol>
            </li>

            <li>
              <h2>Termination</h2>
              <ol className="decimal-counter o2">
                <p>
                  <strong>
                    <em>Termination by you</em>
                  </strong>
                </p>
                <li>
                  You may delete your Player Profile and uninstall the App at
                  any time to discontinue your participation in the Contests.
                </li>
                <li>
                  We will not be liable to return the amount that was available
                  in the Winnings Portfolio and Winnings associated with the
                  Profile and the same will be transferred to Mega’s account if:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      You have not withdrawn all Winnings in your Winnings
                      Portfolio within a period of 30 (thirty) days from
                      uninstalling the App or foreclosure of the App. Mega will
                      periodically reach send out communications to you through
                      the contact details registered with us, reminding you to
                      withdraw your Winnings, after you uninstall the App or
                      your Player Profile is foreclosed;
                    </li>
                    <li>
                      You have created a Player Profile, which is not in
                      accordance with the Terms specifically set out in 1.5; or
                    </li>
                    <li>
                      You have not completed your KYC as set out in 10.8 above.
                    </li>
                  </ol>
                  <p>
                    We will not be liable for any claims against us made by you,
                    your parents or guardians in this regard.
                  </p>
                </li>
                <p>
                  <strong>
                    <em>Foreclosure owing to inactivity</em>
                  </strong>
                </p>
                <li>
                  If a player shows no activity on their account for more than 30 consecutive days, we will consider them inactive. Once marked as inactive, their account will be foreclosed, and the balances from both the deposit wallet and the withdrawal wallet will be transferred to GetMega's wallet.
                </li>
                <li>
                  A player is classified as inactive under the following conditions:
                  <ul>
                    <li>No Gameplay: The player has not participated in any games during the 30-day period.</li>
                    <li>No Deposits: The player has not added any funds to their account within the 30-day period.</li>
                    <li>No Withdrawals: The player has not made any withdrawals from their account in the same timeframe.</li>
                  </ul>
                </li>
                <li>
                  This policy helps maintain active engagement within the platform and ensures proper account management for all users.
                </li>

                <p>
                  <strong>
                    <em>Termination by us</em>
                  </strong>
                </p>
                <li>
                  We have the right to (a) suspend your access to the App and/or
                  any of the Games; (b) terminate your Player Profile; (c) stop
                  the refund and/or block any unused funds in the Player’s
                  Profile; and/or (d) return or forfeit any Winnings as the case
                  maybe, forthwith, at any time:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Forfeit the amounts In the event of inactivity for a
                      continuous period of 335 days;
                    </li>
                    <li>For any purpose, without notice;</li>
                    <li>
                      If you have been or we have reasonable grounds to be
                      believe that you have breached any of our Policies, or
                      been convicted of an offence which involves moral
                      turpitude or any offence under Indian laws;
                    </li>
                    <li>
                      If you have registered or are accessing the App from any
                      jurisdiction (including Indian states) where playing such
                      Games are prohibited; or if it comes to our notice that
                      conduct of any Games or the App is prohibited in a
                      particular jurisdiction (including Indian states); or
                    </li>
                    <li>
                      If we believe that you are indulging in unfair or
                      irresponsible gaming.
                    </li>
                  </ol>
                </li>
                <li>
                  You agree that the availability of the App and/or any of the
                  Games and Your ability to access the App and/or any of the
                  Games is subject to our sole discretion. You acknowledge that
                  the App and/or any of the Games may not be available at all
                  times.
                </li>
                <li>
                  If a player is involved in a duplicitous transaction during
                  any of their deposits, then their account will be blocked and
                  we will empty their app wallets of any deposit / bonus money.
                </li>
                <li>
                  We have the right to suspend, withdraw or cease any Contest,
                  Game or the entire App at any time without providing any
                  notice or being liable to you in any manner whatsoever. In the
                  event we are required to withdraw or cease any Contest, Game
                  or the entire App to comply with any regulatory or statutory
                  directions, we may, in our sole discretion, refund all amounts
                  present in your Winnings Portfolio or Remittance Portfolio to
                  your Designated Account, after deducting applicable charges
                  and taxes.
                </li>
              </ol>
            </li>

            <li>
              <h2>Some Game Specific Terms</h2>
              <ol className="decimal-counter o2">
                <li>
                  In relation to Trivia Games, we have the right to decide the
                  questions and the answer to each question to be used in the
                  Game, in our sole discretion. We shall have the sole authority
                  to decide the validity of any answer and do not guarantee that
                  any questions and/or the corresponding answers will be
                  complete or accurate in any manner. We shall not be liable for
                  any loss incurred by you due to any incorrect questions and/or
                  answers or any discrepancy or errors in the same.
                </li>
                <li>
                  We also have the sole discretion to decide the points,
                  weightage attached, and time provided to respond, to each of
                  the questions in any of the Games.
                </li>
                <li>
                  We may introduce new features in any of the Games including
                  introducing the option to avail hints, increase or decrease
                  time or provide other alternatives in relation to existing
                  Games. We may also alter the format of the existing Games or
                  introduce new Games, at our sole discretion.
                </li>
              </ol>
            </li>

            <li>
              <h2>Responsible Gaming</h2>
              <ol className="decimal-counter o2">
                <li>
                  We offer Games on the App for entertainment only and are
                  committed to promoting fair play and responsible gaming.
                </li>
                <li>
                  To continue enjoying the App and the Games offered on it
                  without such enjoyment resulting in any adverse personal,
                  financial or social consequences, it is imperative that you
                  play responsibly that is you minimise your indulgence or do
                  not indulge in game-play beyond your means or for excessive
                  periods of time.
                </li>
                <li>
                  By creating a Player Profile and playing Games on the App, you
                  agree that you will be engage in responsible gaming by:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Understanding and acknowledging that the Games are for
                      entertainment only and carry with them the risk of losing;
                    </li>
                    <li>
                      Striking a balance between the time you spend on the App
                      playing Games and other aspects of your life such as
                      focusing on your job or spending time with your family;
                    </li>
                    <li>
                      Ensuring that you play Games in a fair manner without
                      resorting to any means of unfairness or cheating;
                    </li>
                    <li>Playing for entertainment and not for making money;</li>
                    <li>
                      Playing only with your own money which you can afford to
                      lose;
                    </li>
                    <li>
                      Deciding in advance how much money and time you will spend
                      playing Games on the App for a particular session and
                      sticking to that limit regardless of your performance;
                    </li>
                    <li>
                      Not playing Games on the App when you are upset, tired, or
                      depressed as it is difficult to make good decisions when
                      you are feeling low;
                    </li>
                    <li>
                      Completing all your important tasks before playing Games
                      on the App so that you can play with a free and calm mind;
                    </li>
                    <li>
                      Avoiding using the entire amount available in your
                      Remittance Portfolio and Winnings Portfolio in a single
                      session of playing; and
                    </li>
                    <li>
                      Taking time outs and breaks regularly regardless of your
                      performance in the Games and especially if you have lost
                      continuously.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h2>Minimum Technical Requirements</h2>
              <ol className="decimal-counter o2">
                <li>
                  In order to ensure optimal use of the App, you will need to
                  access the App on an android phone with a RAM of at least 2 GB
                  and a 4G internet connection (“
                  <strong>Compatible Device</strong>”). We will not be
                  responsible for sub-optimal results because of device and
                  internet connections.
                </li>
                <li>
                  You acknowledge that these Compatible Devices may be
                  maintained and controlled by third party providers and
                  therefore, platforms, software and/or devices that qualify at
                  present as Compatible Devices may cease to be Compatible
                  Devices in the future. Should your platform, software and/or
                  device cease to be compatible, you agree that it is your sole
                  responsibility to procure a Compatible Device to be able to
                  continue using the App and we carry no liability for the same.
                  Your use of third-party Compatible Devices are governed by the
                  terms and conditions of such third parties.
                </li>
                <li>
                  You agree that the quality of the App and/or Games may vary
                  depending on a variety of parameters such as your location,
                  bandwidth, Compatible Device, Internet availability and speed,
                  etc.
                </li>
              </ol>
            </li>

            <li>
              <h2>User Obligations</h2>
              <ol className="decimal-counter o2">
                <li>
                  The App is to be used only for their intended purpose in a
                  bona fide manner, and is not to be misused in any manner,
                  including for undertaking fraudulent transactions,
                  impersonation or any such illegal activity.
                </li>
                <li>
                  You warrant that you are using the App on your own initiative
                  and that you are responsible for compliance with any and all
                  applicable laws in relation to your use of the App.
                </li>
                <li>
                  You shall not:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Use the App for any purpose that is unlawful or prohibited
                      by the Terms;
                    </li>
                    <li>
                      Circumvent in any manner any geotagging or geographical
                      restrictions that may be imposed on the use of the App and
                      the playing of any of the Games;
                    </li>
                    <li>
                      Impose an unreasonable or disproportionately large load on
                      our infrastructure;
                    </li>
                    <li>
                      Attempt to or gain unauthorized access to any portion or
                      feature of the App including accounts, or any other
                      systems or networks connected to the App or to any server,
                      computer, network, or to any of the services by hacking,
                      password “mining” or any other illegitimate means;
                    </li>
                    <li>
                      Directly or indirectly solicit the account information of
                      other users or access or try to access any account which
                      does not belong to you;
                    </li>
                    <li>
                      Probe, scan or test the vulnerability of the App or any
                      network connected to the App or the Games or breach the
                      security or authentication measures on the App or any
                      network connected to the App;
                    </li>
                    <li>Extract data from the App;</li>
                    <li>
                      Attempt to circumvent the regular operation of the App, or
                      reduce the fees or consideration that we may derive from
                      the App by any means including by creating multiple
                      accounts, redirecting traffic or following other
                      fraudulent or deceptive practices or undertake any actions
                      to undermine the integrity of the data, systems or
                      networks used by us in the App or gain unauthorized access
                      to such data, systems or networks;
                    </li>
                    <li>
                      Collect any user information through automated means,
                      including but not limited to bots, robots, spiders and
                      scrapers without our prior written permission;
                    </li>
                    <li>
                      Reproduce, duplicate, copy, sell, visit, distribute, use
                      or otherwise exploit the App for any commercial purposes
                      except as in accordance with the Policies;
                    </li>
                    <li>
                      Introduce any computer viruses or other destructive
                      devices and codes that have the effect of damaging,
                      interfering with, intercepting or expropriating any
                      software or hardware system, data or personal information;
                    </li>
                    <li>
                      Facilitate or encourage any violation of these Terms or
                      our other Policies, including the Privacy Policy, as
                      amended from time to time;
                    </li>
                    <li>
                      Make any statement(s) or comment(s) on the App which
                      is/are inaccurate, false, unfair or defamatory to us or
                      other users or which violates the legal right of others;
                      and
                    </li>
                    <li>
                      Engage in any activities that would otherwise create any
                      liability for us or our affiliates.
                    </li>
                  </ol>
                </li>
                <li>
                  Further, you agree not to upload, host, display, publish,
                  share or otherwise make available on the App any content or
                  information that:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Belongs to another person and to which you do not have any
                      right to;
                    </li>
                    <li>
                      Contains any content which is non-compliant with the
                      Information Technology Act, 2000, rules, regulations, and
                      guidelines made thereunder, including Rule 3 of The
                      Information Technology (Intermediaries Guidelines) Rules,
                      2011, Terms or Privacy Policy, as amended or re-enacted
                      from time to time; or
                    </li>
                    <li>
                      Infringes any intellectual property rights of any third
                      party.
                    </li>
                  </ol>
                </li>
                <li>
                  You agree to be fair, accurate and non-disparaging while
                  leaving comments, feedback, testimonials and reviews on or
                  about the App and/or any of the Games.
                </li>
              </ol>
            </li>

            <li>
              <h2>Intellectual Property</h2>
              <ol className="decimal-counter o2">
                <li>
                  The name ‘Mega’ and all corresponding marks, logos, designs,
                  trade dress, trademarks that we use in connection with the App
                  are our intellectual property and cannot be used by you for
                  any purposes not specified in these Terms.
                </li>
                <li>
                  All intellectual property in the App, Games and in the
                  proprietary material, content and information made available
                  on the App including the graphics, images, photographs, logos,
                  trademarks, the appearance, organisation and layout of the App
                  and the underlying software code belongs to us.
                </li>
                <li>
                  You must not copy, modify, alter, decompile, reverse engineer,
                  publish, broadcast, distribute, sell or transfer (whether in
                  whole or in part) any such material, except as expressly
                  permitted in these Terms.
                </li>
                <li>
                  If you believe any content on the App infringes your
                  intellectual property rights, you may notify us by contacting
                  us on the details provided below.
                </li>
              </ol>
            </li>

            <li>
              <h2>Breach of Terms</h2>
              <p>
                If you have, or we have reasonable grounds to believe that you
                have violated these Terms, or that your use conflicts or
                interferes with our reputation, interest or might subject us to
                unfavourable legal or regulatory action in any way, we can
                indefinitely suspend or terminate your access to the App at any
                time, and report such action to relevant authorities. We reserve
                the right to take recourse to all available remedies under these
                Terms, applicable law or equity in furtherance of the above.
              </p>
            </li>

            <li>
              <h2>Indemnity</h2>
              <p>
                You agree to defend, indemnify and hold harmless us, our
                affiliates, directors, officers, employees and agents from and
                against any and all claims, damages, obligations, losses,
                liabilities, costs or debt, and expenses (including but not
                limited to attorneys’ fees) arising from (i) your use of and
                access to the App; or (ii) Your violation of any term of these
                Terms; or (iii) Your violation of any third party right,
                including without limitation any privacy, publicity or
                intellectual property right; (iv) Your breach of applicable
                law(s); (v) any unauthorized, wrongful, unlawful or illegal use
                of your Player Profile by a third party with or without your
                consent.
              </p>
            </li>

            <li>
              <h2>Disclaimer of Warranties and Limitation of Liability</h2>
              <ol className="decimal-counter o2">
                <li>
                  YOU AGREE AND ACKNOWLEDGE THAT THE APP AND THE GAMES ARE
                  PROVIDED ‘AS-IS’ WITHOUT WARRANTIES OF ANY KIND, EITHER
                  EXPRESS OR IMPLIED INCLUDING THOSE OF TITLE, FITNESS FOR
                  PURPOSE AND MERCHANTABILITY, COMPLETENESS, AVAILABILITY,
                  SECURITY, COMPATIBILITY OR NON-INFRINGEMENT, TO THE FULLEST
                  EXTENT PERMITTED BY APPLICABLE LAW. YOU ACKNOWLEDGE THAT THE
                  FUNCTIONING OF THE APP IS NOT ERROR FREE, UNINTERRUPTED OR
                  FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS.
                </li>
                <li>
                  While there are no pre-set limits on your remittance to play
                  Games on the App, you are required to use your discretion and
                  judgment for remitting and maintaining your monetary Winnings,
                  as reflected in the Winnings Portfolio, on the App We do not
                  guarantee the safety and security of the amounts reflected in
                  your Remittance Portfolio or Winnings Portfolio and these are
                  subject to routine commercial risks.
                </li>
                <li>
                  We shall not be liable for any loss or damage or failure to
                  comply with or delay in complying with our obligations under
                  these Terms which is caused directly or indirectly by any
                  event or circumstances beyond our reasonable control including
                  due to system failure, network issues, technical snags or loss
                  of data due to any reasons whatsoever including any of the
                  preceding reasons, act of God, floods, epidemics, quarantine,
                  riot or war.
                </li>
                <li>
                  In no event shall we be liable for any special, punitive,
                  incidental, indirect or consequential damages, including but
                  not limited to any loss of profits, revenue, business or data
                  of any kind in connection with the App, these Terms, your
                  inability to use the App, or your interactions with other
                  users on the App, whether under contract, tort, or otherwise,
                  including for negligence, even if we has been informed in
                  advance of the possibility of such damages.
                </li>
                <li>
                  Notwithstanding any of the foregoing provisions, the aggregate
                  liability of Mega, its employees, agents, affiliates,
                  representatives or anyone acting on its behalf with respect to
                  each user of the App for all claims arising from the access
                  to, or use of, the App shall be limited to the price you have
                  paid for the Pack in respect of which you have a claim.
                </li>
                <li>
                  The limitations and exclusions of liability to you under the
                  Terms shall apply to the maximum extent permitted by law and
                  shall apply whether or not we have been advised of, or should
                  have been aware of, the possibility of any such losses
                  arising.
                </li>
              </ol>
            </li>

            <li>
              <h2>Availability of App</h2>
              <ol className="decimal-counter o2">
                <li>
                  We do not warrant that:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      The App will be constantly available or available at all.
                      We shall have no liability to you for any interruption or
                      delay in access to the App or the Games played through it,
                      irrespective of the cause;
                    </li>
                    <li>
                      The information on the App is complete, true, accurate or
                      non-misleading;
                    </li>
                    <li>
                      The App is secure or free of viruses, Trojans or other
                      malware; or
                    </li>
                    <li>
                      The contents of the App do not infringe any intellectual
                      property rights.
                    </li>
                  </ol>
                </li>
                <li>
                  The access to the App may also be occasionally suspended or
                  restricted or certain features may be unavailable to allow for
                  scheduled maintenance, repairs or upgrading the App at any
                  time without prior notice.
                </li>
                <li>
                  We reserve the right to modify the App, our technology and
                  Games offered of the App, from time to time.
                </li>
              </ol>
            </li>

            <li>
              <h2>Information and Privacy Policy</h2>
              <ol className="decimal-counter o2">
                <li>
                  We reserve the right to store and retain any and all
                  information provided by you including the Games played,
                  Winnings won, Player Profile details and details of the
                  transactions conducted on the App for our business purposes as
                  well as to comply with applicable laws.
                </li>
                <li>
                  Any personal information you supply to us when you use the App
                  will be used in accordance with our{' '}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </li>
              </ol>
            </li>

            <li>
              <h2>Third party links</h2>
              <ol className="decimal-counter o2">
                <li>
                  The App may include hyperlinks to various external websites,
                  applications, content, or resources and may also include
                  advertisements (“<strong>Third-Party Links</strong>”). We have
                  no control over such Third-Party Links present on the App,
                  which are provided by persons or companies other than us. You
                  acknowledge and agree that we are not responsible for any
                  collection or disclosure of your information by any external
                  sites, applications, companies or persons thereof. The
                  presence of any Third-Party Links on the App cannot be
                  construed as a recommendation, endorsement or solicitation of
                  any material or content present on such Third-Party Links, or
                  any other material on or available through such Third-Party
                  Links.
                </li>
                <li>
                  You further acknowledge and agree that we are not liable for
                  any loss or damage which may be incurred by you as a result of
                  the collection and/or disclosure of your information through
                  such Third-Party Links or as a result of any reliance placed
                  by you on the completeness, accuracy or existence of any
                  advertising or other materials on, or available through such
                  Third-Party Links. This will include all transactions and
                  information transmitted on such Third-Party Links between you
                  and any third-party sites or applications or resources. All
                  transactions undertaken by you on the Third-Party Links are
                  strictly bipartite. We shall not be liable for any disputes
                  arising from, or in connection with, such transactions between
                  you and such third parties.
                </li>
                <li>
                  Such third-party websites, and external applications or
                  resources, accessible using the Third-Party Links may have
                  their own privacy policies governing the collection, storage,
                  retention and disclosure of your information that you may be
                  subject to. We recommend that you exercise reasonable
                  diligence, as you would in traditional offline channels and
                  practice judgment and common sense before committing to any
                  transaction or exchange of information, including but not
                  limited to reviewing the third-party website or application’s
                  privacy policy.
                </li>
              </ol>
            </li>

            <li>
              <h2>Severability and Waiver</h2>
              <p>
                If any provisions of these Terms are found to be invalid by any
                court having competent jurisdiction, the invalidity of such
                provision shall not affect the validity of the remaining
                provisions of these, which shall remain in full force and
                effect. No waiver of any term of these Terms shall be permitted.
              </p>
            </li>

            <li>
              <h2>Governing law and jurisdiction</h2>
              <p>
                These Terms shall be governed by and interpreted and construed
                in accordance with the laws of India as applicable between
                resident Indians. Subject to the dispute resolution term below,
                any disputes pertaining to the App shall be subject to the
                exclusive jurisdiction of the appropriate courts of Bengaluru,
                India.
              </p>
            </li>

            <li>
              <h2>Dispute Resolution</h2>
              <p>
                All disputes arising from or in connection with these Terms
                shall be settled amicably between the parties within 30 (thirty)
                days, failing which it shall be referred to and finally settled
                by arbitration in accordance with the Indian Arbitration and
                Conciliation Act, 1996 as amended from time to time, presided
                over by a sole arbitrator appointed mutually appointed by the
                Parties failing which it shall be appointed by the High Court of
                Bengaluru. Judgment upon the arbitral award may be entered in
                any court having jurisdiction over the Parties or their assets.
                The arbitration proceedings shall be held at Bengaluru. The
                arbitration proceedings shall be conducted in the English
                language.
              </p>
            </li>

            <li>
              <h2>Contact us</h2>
              <ol className="decimal-counter o2">
                <li>
                  Please contact us if you have any questions or comments on the
                  App, these Terms and any of the Policies (including all
                  inquiries related to copyright infringement) regarding the
                  App.
                </li>
                <li>
                  This document is an electronic record and is generated by a
                  computer system and does not require any physical or digital
                  signatures.
                </li>
              </ol>
            </li> */}
          </Container>
        </div>
      </ol>
      {/* </Container> */}
    </div>
  )
}

export default TermsAndConditions
