import React from 'react'
import MainBannerAnnouncement from '../Announcement/MainBanner'
import InfoCard from '../../../Games/CategoryAndGamesInfo/InfoCard'
import RummyInfo from '../../../Games/CategoryAndGamesInfo/RummyInfo'
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import {
  CONTACT_US,
  POKER_TDS,
  PRIVACY_POLICY,
  RUMMY_TDS,
  TERMS,
} from '../../../internal-links'
import UpdatedHomeFAQs from '../../../Index/HomeFAQs/UpdatedHomeFAQs'
import LinkButton from '../../LinkButton/LinkButton'
import { StaticImage } from 'gatsby-plugin-image'
import {
  pokerOneLinksHomePage,
  rummyOneLinksHomePage,
} from '../../../one-links'

const CloseApp = () => {
  return (
    <>
      <MainBannerAnnouncement />
      <Container>
        <InfoCard>
          <RummyInfo
            lang="english"
            englishContentFromPerent={
              <>
                <h2>Withdrawal Instructions:</h2>
                <h3>Step-by-step guidance for withdrawing funds:</h3>
                <h3>Step 1: Download the latest app build:</h3>
                <ul
                  style={{
                    listStyle: 'none',
                    padding: 0,
                  }}
                >
                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {/* Use StaticImage correctly with a valid path */}
                    <StaticImage
                      src="./../../../../images/thumbnails/Rummy-Thumbnails.png" // Correct path from src folder
                      alt="rummy"
                      width={40}
                    />{' '}
                    <StaticImage
                      src="./../../../../images/thumbnails/android-logo.png" // Correct path for Android logo
                      alt="Android Logo"
                      width={20}
                    />
                    <LinkButton
                      style={{
                        padding: '10px 25px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px', // Space between text and logo
                      }}
                      textStyles={{
                        fontWeight: 500,
                        textTransform: 'capitalize',
                      }}
                      oneLink={rummyOneLinksHomePage}
                    >
                      Mega Rummy
                    </LinkButton>
                  </li>

                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <StaticImage
                      src="./../../../../images/thumbnails/Verticle_TexasHoldem.png" // Correct path
                      alt="poker"
                      width={40}
                    />
                    <StaticImage
                      src="./../../../../images/thumbnails/android-logo.png" // Correct path for Android logo
                      alt="Android Logo"
                      width={20}
                    />
                    <LinkButton
                      style={{
                        padding: '10px 30px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px', // Space between text and logo
                      }}
                      textStyles={{
                        fontWeight: 500,
                        textTransform: 'capitalize',
                      }}
                      oneLink={pokerOneLinksHomePage}
                    >
                      Mega Poker
                    </LinkButton>
                  </li>
                </ul>

                <h3>Step 2: Open the app and access the withdrawal option.</h3>
                <h3>Step 3: Complete the withdrawal before 15th Jan 2025</h3>
                <div>
                  <p>
                    <strong>Note:</strong> Withdrawals will not be possible
                    after 15th Jan 2025.Please ensure you download the APK file
                    and withdraw your funds before this deadline..
                  </p>
                </div>
              </>
            }
          />
        </InfoCard>

        <InfoCard>
          <RummyInfo
            lang="english"
            englishContentFromPerent={
              <>
                <h2>Customer Support:</h2>
                <h3>
                  Reach out to us for any assistance or queries regarding the
                  closure or withdrawal process.
                </h3>
                <ul>
                  <li>
                    <p>
                      For <strong>Mega Rummy:</strong>
                      <ul>
                        <li>
                          <p>
                            Email: <strong>help.rummy@getmega.com</strong>
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <p>
                      For <strong>Mega Poker:</strong>
                      <ul>
                        <li>
                          <p>
                            Email: <strong>help.poker@getmega.com</strong>
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </>
            }
          />
        </InfoCard>

        <InfoCard>
          <RummyInfo
            lang="english"
            englishContentFromPerent={
              <>
                <h2>Supporting Links:</h2>
                <h3>Here are some additional resources for your reference:</h3>

                <ul>
                  <li>
                    <p>
                      <Link to={TERMS}>Terms & Conditions</Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to={RUMMY_TDS}>Mega Rummy TDS Policy</Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to={POKER_TDS}>Mega Poker TDS Policy</Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to={CONTACT_US}>Contact Support</Link>
                    </p>
                  </li>
                </ul>
              </>
            }
          />
        </InfoCard>
      </Container>

      <UpdatedHomeFAQs hideAllFaqsLink={true} />
    </>
  )
}

export default CloseApp
